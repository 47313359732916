import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, H2, Reviews, ReviewRating, NamedLink } from '../../components';
import ReviewForm from '../TransactionPage/ReviewForm/ReviewForm';

import css from './ListingPage.module.css';

const SectionReviews = props => {
  const {
    reviews,
    fetchReviewsError,
    onSubmitReview,
    reviewSent,
    sendReviewInProgress,
    sendReviewError,
    showReviewForm,
    rating,
    userTypes,
    listingTitle,
    isAuthenticated,
  } = props;
  return (
    <section id="reviews" className={css.sectionReviews}>
      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage id="ListingPage.reviewsTitle" values={{ count: reviews.length }} />
      </Heading>
      {!!rating ? (
        <div className={css.rating}>
          <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
            <FormattedMessage id="ListingPage.rating" values={{ rating: rating.toFixed(1) }} />
          </Heading>
          <div className={css.sectionHeadingWithExtraMargin}>
            <ReviewRating
              rating={rating}
              className={css.reviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
          </div>
        </div>
      ) : null}
      {fetchReviewsError ? (
        <H2 className={css.errorText}>
          <FormattedMessage id="ListingPage.reviewsError" />
        </H2>
      ) : null}
      <Reviews reviews={reviews} userTypes={userTypes} />
      {showReviewForm ? (
        <div className={css.reviewFormContainer}>
          <ReviewForm
            onSubmit={onSubmitReview}
            reviewSent={reviewSent}
            sendReviewInProgress={sendReviewInProgress}
            sendReviewError={sendReviewError}
            listingTitle={listingTitle}
          />
        </div>
      ) : null}
      {isAuthenticated ? null : (
        <div className={css.reviewUnAuthenticated}>
          <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
            <FormattedMessage id="ReviewForm.reviewRatingLabel" />
          </Heading>
          <p className={css.reviewUnAuthenticatedInfo}>
            <FormattedMessage id="ListingPage.reviewUnAuthenticatedInfo" />
          </p>
          <div className={css.authenticateLinks}>
            <NamedLink name="LoginPage" className={css.loginLink}>
              <span className={css.topbarLinkLabel}>
                <FormattedMessage id="TopbarDesktop.login" />
              </span>
            </NamedLink>
            <NamedLink name="SignupPage" className={css.signupLink}>
              <span className={css.topbarLinkLabel}>
                <FormattedMessage id="TopbarDesktop.signup" />
              </span>
            </NamedLink>
          </div>
        </div>
      )}
    </section>
  );
};

export default SectionReviews;
